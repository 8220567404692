.react-calendar {
  @apply w-full rounded border-0 shadow-xl xs:w-[350px];
}

.react-calendar__navigation {
  @apply rounded-t;
}

.react-calendar__month-view__weekdays {
  @apply !grid grid-cols-7 gap-2;
}

.react-calendar__month-view__days {
  @apply !grid grid-cols-7 gap-2;
}

.react-calendar__month-view__weekdays__weekday {
  @apply lowercase text-neutral-700;
}

.react-calendar__month-view__weekdays__weekday abbr {
  @apply cursor-default no-underline;
}

.react-calendar__navigation .react-calendar__navigation__arrow {
  @apply rounded;
}
.react-calendar__navigation .react-calendar__navigation__arrow[disabled] {
  @apply bg-white text-neutral-500;
}

.react-calendar__navigation .react-calendar__navigation__label {
  @apply text-xs font-semibold capitalize text-neutral-800;
}

.react-calendar__navigation .react-calendar__navigation__label[disabled] {
  @apply bg-white;
}

.react-calendar__tile {
  @apply py-2;
}

.react-calendar .react-calendar__tile,
.react-calendar__tile.react-calendar__month-view__days__day--weekend {
  @apply rounded text-xs font-semibold text-neutral-800;
}

.react-calendar .react-calendar__tile[disabled] {
  @apply bg-white text-neutral-500;
}

.react-calendar .react-calendar__tile.react-calendar__tile--now {
  @apply bg-white;
}

.react-calendar .react-calendar__tile:enabled:hover,
.react-calendar .react-calendar__tile:enabled:focus,
.react-calendar .react-calendar__tile--now:enabled:hover,
.react-calendar .react-calendar__tile--now:enabled:focus {
  @apply bg-neutral-500;
}
.react-calendar__tile.react-calendar__tile--active:enabled,
.react-calendar__tile.react-calendar__tile--active:enabled:focus {
  @apply bg-primary-500 text-neutral-800;
}

.react-calendar__tile.react-calendar__tile--active:hover,
.react-calendar__tile.react-calendar__tile--active:focus {
  @apply bg-primary-500;
}
