input[type='range'] {
  width: 100%;
  margin: -0.2px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply bg-neutral-500;
  border: 0;
  border-radius: 4px;
  width: 100%;
  height: 4px;
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  @apply w-10 bg-neutral-700 md:w-64;
  margin-top: 0px;
  height: 4px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']:hover::-webkit-slider-thumb {
  height: 8px;
  transform: translateY(-25%);
}

input[type='range']:focus::-webkit-slider-runnable-track {
  @apply bg-neutral-500;
}

input[type='range']::-moz-range-track {
  @apply bg-neutral-500;
  border: 0.2px solid rgba(1, 1, 1, 0);
  border-radius: 4px;
  width: 100%;
  height: 4px;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  @apply w-10 bg-neutral-700 md:w-64;
  height: 4px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}
input[type='range']:hover::-moz-range-thumb {
  height: 8px;
}

input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 0.8px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid rgba(1, 1, 1, 0);
  border-radius: 2.6px;
}
input[type='range']::-ms-fill-upper {
  @apply bg-neutral-700;
  border: 0.2px solid rgba(1, 1, 1, 0);
  border-radius: 2.6px;
}
input[type='range']::-ms-thumb {
  @apply w-10 bg-primary md:w-64;
  height: 4px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:hover::-ms-thumb {
  height: 8px;
}
input[type='range']:focus::-ms-fill-lower {
  @apply bg-neutral-500;
}
input[type='range']:focus::-ms-fill-upper {
  @apply bg-neutral-700;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
