@import 'tailwindcss/base';
@import '../../shared/styles/range-slider.css';

@import '../../shared/node_modules/react-calendar/dist/Calendar.css';
@import '../../shared/components/range-calendar/range-calendar.css';

table {
  margin-top: 0;
  margin-bottom: 0;
}

label {
  margin-bottom: unset;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fff;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-cancel-button {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='%23767676'/%3E%3Cpath d='M5.99946 4.6665L4.66663 5.99984L6.66711 7.99861L4.66663 9.99984L5.99946 11.3332L7.99996 9.33195L10.0005 11.3332L11.3333 9.99984L9.33281 7.99861L11.3333 5.99984L10.0005 4.6665L7.99996 6.66773L5.99946 4.6665Z' fill='white'/%3E%3C/svg%3E%0A");
  @apply w-4;
  @apply h-4;
}

.hide-scrollbar {
  scrollbar-width: 0;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.animate-gradient {
  background-size: 200% 200%;
}

@tailwind components;
@tailwind utilities;

.hub-toaster div[role='status'] {
  @apply justify-start;
}

/* Algolia Autocomplete */
.aa-Panel {
  @apply z-40;
}

/* Hide browser default icon in date inputs */
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  @apply absolute inset-0 h-full w-full opacity-0;
}